<template>
  <div :class="['app-file-loader', mods]">
    <i
      v-if="allowDelete && image"
      class="el-icon-close app-file-loader__icon"
      @click="$emit('delete')"
    ></i>

    <!-- <pre>value: {{ value }}</pre> -->

    <p class="app-file-loader__title">
      {{ name[0].toUpperCase() + name.slice(1) }}
    </p>

    <input
      id="file"
      type="file"
      ref="file"
      :accept="accept"
      @change="handlerLoadFile"
    />

    <div>
      <img
        class="app-file-loader__image"
        v-if="image && type == 'image'"
        :src="image"
        alt
      />

      <p class="app-file-loader__file-name" v-else-if="image && type == 'file'">
        {{ image.split("/")[image.split("/").length - 1] }}
      </p>
    </div>
  </div>
</template>

<script>
import Button from "@/components/root/Button";

import { mapGetters, mapActions, mapMutations } from "vuex";
import * as types from "@/store/mutation-types";

export default {
  name: "app-file-loader",

  props: {
    value: {},

    accept: {
      type: String,
      default: "image/x-png, image/gif, image/jpeg, image/x-adobe-dng"
    },

    name: {
      type: String,
      default: null
    },

    image: {
      type: String,
      default: null
    },

    invalid: {
      type: Boolean,
      default: false
    },

    index: {
      type: Number
      // default: 1
    },

    isShow: {
      type: Boolean,
      default: true
    },

    type: {
      type: String,
      default: "image"
    },

    allowDelete: {
      type: Boolean,
      default: true
    }
  },

  components: {
    Button
  },

  data: () => ({
    tPrex: "app.fileLoader",

    link: null
  }),

  computed: {
    mods() {
      return {
        "app-file-loader_invalid": this.invalid
      };
    }
  },

  watch: {
    file(value) {
      if (this.index == this.photoIndex) {
        this.$emit("input", value);

        this.link = URL.createObjectURL(value);
      }
    },

    isShow() {
      console.log("isShow", isShow);
      console.log(document.getElementById("file"));

      document.getElementById("file").value = "";
    }
  },

  methods: {
    handlerLoadFile() {
      var file = this.$refs.file.files[0];

      if (file) {
        console.log("file", file);

        this.link = URL.createObjectURL(file);
        this.$emit("input", file);
      }
    },

    handleChangeFile(file) {
      this.$emit("input", file);

      this.link = URL.createObjectURL(file);
    }
  }
};
</script>

<style lang="scss" scoped>
.app-file-loader {
  /* width: 100%; */
  margin-bottom: 15px;

  flex: 1;
  display: flex;
  // align-items: center;
  flex-direction: column;

  position: relative;

  &__title {
    margin-bottom: 10px;

    /* color: #fff; */
    font-size: 14px;
  }

  &__image {
    margin-top: 1rem;

    height: 150px;
  }

  &__file-name {
    margin-top: 20px;

    font-size: 12px;
  }

  &__icon {
    position: absolute;
    right: 10px;

    margin-top: 5px;

    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      opacity: 0.7;
      transition: all 0.3s;
    }
  }
}
</style>
