<template>
  <div :class="['app-button', mods]" @click="!disabled && !isLoading && $emit('click')">
    <p
      class="app-button__text"
      :style="textStyle"
      @click="!disabled && !isLoading && $emit('click')"
    >{{text}}</p>
  </div>
</template>

<script>
export default {
  name: "app-button",

  components: {},

  props: {
    text: {
      type: String,
      default: ""
    },

    transfusion: {
      type: Boolean,
      default: false
    },

    disabled: {
      type: Boolean,
      default: false
    },

    isLoading: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    mods() {
      return {
        "app-button_transfusion": this.transfusion && !this.disabled,
        "app-button_disabled": this.disabled
      };
    },

    textStyle() {
      return {
        opacity: this.isLoading ? 0 : 1
      };
    }
  }
};
</script>

<style lang="scss" scoped >
@keyframes transfusion {
  0% {
    background-position: -500%;
  }
  100% {
    background-position: 500%;
  }
}

.app-button {
  // width: 100%;
  height: 2.5rem;
  padding: 0 2rem;
  // height: 2.5rem;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  border-radius: 20px;
  filter: drop-shadow(0px 5px 7.5px #15151b);
  background-color: #ec008c;

  // background: #ec008c;
  background-repeat: no-repeat;
  background-size: 40%;

  font-size: 1.125rem;
  font-weight: 600;
  line-height: 1;
  color: #ffffff;

  cursor: pointer;

  &_disabled {
    cursor: default;
    opacity: 0.6;
  }

  &_transfusion {
    background: linear-gradient(
        105deg,
        transparent,
        rgba(#fff, 0.5),
        transparent
      ),
      #ec008c;
    background-repeat: no-repeat;
    background-size: 45%;

    overflow: hidden;
    animation: transfusion 2.5s linear infinite;
  }

  &__text {
    // line-height: 1;

    cursor: inherit;
  }

  &__loading {
    position: absolute;
  }
}
</style>
